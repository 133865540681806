import { Injectable } from "@angular/core";
import { ApiCallService } from "./apiCall.service";
import { NetworkService } from "./network.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private api: ApiCallService,
    private router: Router,
    private alert: AlertService,
  ) { }

  Register(data: FormData) {
    return this.api.postApiCallNoAuth(NetworkService.register(), data);
  }

  login(data: FormData) {
    return this.api.postApiCallNoAuth(NetworkService.login(), data);
  }

  reSentOtp(data: any) {
    return this.api.postApiCallNoAuth(NetworkService.resendOTP(), data);
  }

  verifyOtp(data: any) {
    return this.api.postApiCallNoAuth(NetworkService.verifyOTP(), data);
  }

  forgotPassword(data: any) {
    return this.api.postApiCallNoAuth(NetworkService.forgetPassowrd(), data);
  }

  changePassword(data: any) {
    return this.api.postApiCallAuth(NetworkService.updatePassword(), data);
  }

  logoutApi() {
    return this.api.postApiCallAuth(NetworkService.logout(), {});
  }

  logOut(): void {
    Swal.fire({
      text: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1b5e28",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        this.logoutApi().subscribe((r) => {
          if (r.status) {
            this.alert.fireToastS(r.message[0]);
            sessionStorage.clear();
            localStorage.clear();
            this.router.navigate(["/auth/login"]);
            localStorage.removeItem("Session_User");
          }
        });

      }
    });
    // sessionStorage.clear();
  }

  // signInWithGoogle(): void {
  //   // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(() => {
  //     this.authService.authState.subscribe((user) => {
  //       console.error(user);
  //     });
  //   });
  // }
}
