import { DOCUMENT } from "@angular/common";
import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "src/app/services/auth.service";
import { CommonService } from "src/app/services/common.service";
import { ProfileService } from "src/app/services/profile.service";
import { LanguageService } from "../../core/services/language.service";

import {
    FormBuilder,
    FormGroup,
    Validators
} from "@angular/forms";
import { AlertService } from "src/app/services/alert.service";
import Validation from "src/app/utils/confirm-password.validator";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  role: string = "";
  name: string = "";
  notificationPage: number = 1;
  NotificationCountPerPage: number = 6;
  totalNotifications: number = 0;
  isLoading: boolean = true;
  notificationList: any[] = [];
  userForm: FormGroup;
  togglePassword: boolean = false;
  togglePassword1: boolean = false;
  togglePassword2: boolean = false;

  submitted = false;
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  notCount: number
  userDetails: any
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private as: AuthService,
    private alert: AlertService,
    private ps: ProfileService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,

    private cs: CommonService
  ) { }

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.userForm = this.formBuilder.group(
      {
        requestFrom: ["web"],
        requestType: ["changePassword"],
        oldPassword: ["", [Validators.required, Validators.minLength(8)]],
        password: ["", [Validators.required, Validators.minLength(8)]],
        cPassword: ["", [Validators.required, Validators.minLength(8)]],
      },
      {
        validators: [
          Validation.match("password", "cPassword"),
          Validation.match("cPassword", "cPassword"),
          Validation.unMatch("oldPassword", "password"),
        ],
      }
    );
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.cookieValue = this._cookiesService.get("lang");
    this.getProfile();
    this.getNotifications();
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  toggleMobileMenu(event: any = null) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    this.as.logOut();
  }

  getProfile() {
    this.cs.getprofile().subscribe((r: any) => {
      // console.log("Profile");
      this.isLoading = true;
      // console.log(r);
      this.userDetails = r.response.userDetail
      this.role = r.role;
      this.name = r.response.userDetail.name;
      this.isLoading = false;
    });
  }

  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  getNotifications() {
    // let data = {
    //   page: this.notificationPage,
    //   countPerPage: this.NotificationCountPerPage,
    // };
    this.cs.getNotification().subscribe((r) => {
      if (r.status) {
        this.notificationList = r.response.notifications
        this.notCount = r.response.notifications.filter((x, i) => { return x.is_read == 0 }).length
      }
    });
  }

  openNotification(id) {
    this.cs.openNotification(id).subscribe(r => {
      if (r.status) {
        this.getNotifications()
      }
    })
  }
  get form() {
    return this.userForm.controls;
  }

  changePassword() {
    if (this.userForm.invalid) {
      this.submitted = true;
      return true;
    }
    this.form.requestType.setValue("changePassword");
    this.form.requestFrom.setValue("web");
    this.as.changePassword(this.userForm.value).subscribe((r) => {
      if (r.status) {
        this.modalService.dismissAll();
        this.router.navigate(["auth/login"]);
        this.alert.fireToastS(r.message[0]);
        sessionStorage.clear();
      }
    });
  }



  changePasswordPop(content) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalService.open(content, ngbModalOptions);
    this.submitted = false;
    this.userForm.reset();
  }

  showPassword() {
    this.togglePassword = !this.togglePassword;
  }

  showPassword1() {
    this.togglePassword1 = !this.togglePassword1;
  }

  showPassword2() {
    this.togglePassword2 = !this.togglePassword2;
  }

  loadMoreNotifications() {
    if (
      this.notificationPage * this.NotificationCountPerPage <
      this.totalNotifications
    ) {
      this.notificationPage = this.notificationPage + 1;
      this.getNotifications();
    }
  }

  viewNotification(data) {
    if (data.type == "approvalList") {
      // this.router.navigate(["/investments", data.id]);
      this.router.navigate(["/requests"]);
    } else {
      this.router.navigate(["/investments", data.id]);
    }
  }
}
