import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    isLayout: true,
  },
  {
    id: 2,
    label: "menus",
    isTitle: true,
  },
  {
    id: 3,
    label: "Dashboard",
    icon: "mc-dashboard",
    link: "/dashboard",
    role: "admin",
    role2: "franchise",
    role3: "customer",
    role4: "cuttingCenter",
    role5: "retailer",
    role6: "devilery",
    role7: "superAdmin",
  },
  {
    id: 4,
    label: "Product Management",
    icon: "mc-product-management",
    role: "superAdmin",
    role2: "superAdmin",
    role3: "superAdmin",
    role4: "superAdmin",
    role5: "superAdmin",
    role6: "superAdmin",
    role7: "superAdmin",
    subItems: [

      {
        id: 16,
        parentId: 4,
        label: "Banner",
        icon: "mc-banner",
        link: "/banner",
        srole: "cuttingCenter",
        srole2: "retailer",
        srole3: "admin",
        srole4: "franchise",
      },

      {
        id: 18,
        parentId: 4,
        label: "Categories",
        icon: "mc-categories",
        link: "/products/categories",
        srole: "cuttingCenter",
        srole2: "retailer",
        srole3: "admin",
        srole4: "franchise",
      },
      {
        id: 18,
        parentId: 4,
        label: "Products",
        icon: "mc-product",
        link: "/products/list",
        srole: "cuttingCenter",
        srole2: "retailer",
        srole3: "admin",
        srole4: "franchise",
      },
      {
        id: 16,
        parentId: 15,
        label: "Brand",
        icon: "mc-brands",
        link: "/brand",
        srole: "cuttingCenter",
        srole2: "retailer",
        srole3: "admin",
        srole4: "franchise",
      },
      {
        id: 18,
        parentId: 15,
        label: "Blog",
        icon: "mc-feeds",
        link: "/blog",
        // srole: "cuttingCenter",
        // srole2: "retailer",
        // srole3: "admin",
        // srole4: "franchise",
      },
      // {
      //   id: 18,
      //   parentId: 15,
      //   label: "Feeds",
      //   icon: "mc-feeds",
      //   link: "feed",
      //   srole: "cuttingCenter",
      //   srole2: "retailer",
      //   srole3: "admin",
      //   srole4: "franchise",
      // },
    ],
  },



  {
    id: 6,
    label: "Sales",
    icon: "mc-product-purchase",
    role: "admin",
    role2: "franchise",
    role3: "customer",
    role4: "cuttingCenter",
    role5: "retailer",
    role6: "devilery",
    role7: "superAdmin",
    subItems: [
      {
        id: 16,
        parentId: 6,
        label: "Order List",
        icon: "mc-order-list",
        link: "/order-list",
      },
      {
        id: 18,
        parentId: 20,
        label: "Sales Report",
        link: "/sales-report",
        icon: "mc-sales-report",
        // srole: 'franchise'
      },
      {
        id: 16,
        parentId: 6,
        label: "Customers",
        icon: "mc-customers",
        link: "customer",
        // srole: 'franchise'
      },
      {
        id: 18,
        parentId: 6,
        label: "Customer Report",
        link: "/customer-report",
        icon: "mc-customer-report",
        // srole: "franchise",
      },
      {
        id: 16,
        parentId: 6,
        label: "Walk-In Customer",
        icon: "mc-walk-in-customer",
        link: "/walk-in-customer",
        srole: 'superAdmin',
        srole2: 'admin',
        srole3: 'franchise'
      },
      {
        id: 16,
        parentId: 6,
        label: "Bulk Order List",
        icon: "mc-bulk-order-list-copy",
        link: "/bulk-order-list",
        // srole: 'cuttingCenter',
        // srole2: 'retailer'
      },
      {
        id: 18,
        parentId: 6,
        label: "Bulk Order Report",
        link: "/bulk-order-report",
        icon: "mc-bulk-order-report",
        // srole: 'franchise',
        srole2: 'cuttingCenter',
        srole3: 'retailer'
      },

    ],
  },


  {
    id: 7,
    label: "Shop Management",
    icon: "mc-management",
    role: "admin",
    role2: "franchise",
    role3: "customer",
    role5: "cuttingCenter",
    role4: "retailer",
    role6: "devilery",
    role7: "superAdmin",
    subItems: [
      // {
      //   id: 16,
      //   parentId: 7,
      //   label: "City",
      //   icon: "mc-city-management",
      //   link: "/city-management",
      //   srole: 'franchise',
      //   srole2: 'admin',
      //   srole3: 'cuttingCenter',
      //   srole4: 'retailer'
      // },
      {
        id: 16,
        parentId: 7,
        label: "MCCRO FCCRO MRO FRO  Shops",
        icon: "mc-user-management",
        link: "/users",
        srole3: 'cuttingCenter',
        srole4: 'retailer'
      },
      {
        id: 16,
        parentId: 7,
        label: "District Management",
        icon: "mc-city-management",
        link: "/district",
        srole: 'franchise',
        srole2: 'admin',
        srole3: 'cuttingCenter',
        srole4: 'retailer'
      },
      {
        id: 18,
        parentId: 7,
        label: "All Shop List",
        link: "/user-report",
        icon: "mc-user-report",
        srole: 'franchise',
        srole3: 'cuttingCenter',
        srole4: 'retailer'
      },

      {
        id: 16,
        parentId: 7,
        label: "Product Stock",
        icon: "mc-stock-management",
        link: "/stock",
        srole: 'cuttingCenter',
        srole4: 'retailer'
      },
      {
        id: 16,
        parentId: 7,
        label: "Mortality",
        icon: "mc-stock-management",
        link: "mortality",
        srole: 'admin',
        srole2: 'franchise',
        srole3: 'retailer',
      },
      {
        id: 16,
        parentId: 7,
        label: "Weightloss Conversion",
        icon: "mc-stock-management",
        link: "weightloss",
        srole: 'admin',
        srole2: 'franchise',
        srole3: 'retailer',
      },
      {
        id: 16,
        parentId: 7,
        label: "Product Return",
        icon: "mc-stock-management",
        link: "product-return",
        srole: 'admin',
        srole2: 'franchise',
        // srole3: 'cuttingCenter',
      },

      {
        id: 16,
        parentId: 7,
        label: "Price Admin",
        icon: "mc-price-management",
        link: "/price-list",
        srole3: 'cuttingCenter',
        srole4: 'retailer'
      },
      // {
      //   id: 18,
      //   parentId: 7,
      //   label: "Price Report",
      //   link: "/price-report",
      //   icon: "mc-price-report",
      //   srole: "cuttingCenter",
      //   srole2: "retailer",
      // },
      {
        id: 18,
        parentId: 7,
        label: "Shop Offers",
        icon: "mc-offer",
        link: "/offer",
        srole: "cuttingCenter",
        srole2: "retailer",
        srole3: 'admin',
        srole4: 'franchise'
      },

    ],
  },

  {
    id: 5,
    label: "Purchase",
    icon: "mc-purchase",
    role: "admin",
    role2: "franchise",
    role3: "customer",
    role4: "cuttingCenter",
    role5: "retailer",
    role6: "devilery",
    role7: "superAdmin",
    subItems: [
      {
        id: 16,
        parentId: 5,
        label: "Shops Product Purchase Orders",
        icon: "mc-product-purchase",
        link: "/purchase-orders",
        // srole2: 'cuttingCenter',
        // srole3: 'retailer'
      },
      {
        id: 16,
        parentId: 5,
        label: "Shops Product Purchase Orders Report",
        link: "/purchase-report",
        icon: "mc-purchase-report",
        // srole2: 'cuttingCenter',
        // srole3: 'retailer'
      },
      {
        id: 16,
        parentId: 7,
        label: "Product Stock",
        icon: "mc-stock-management",
        link: "/stock",
        srole: 'franchise',
        srole2: 'superAdmin',
        srole3: 'admin'
      },
      // {
      //   id: 16,
      //   parentId: 5,
      //   label: "Shop Product Sales",
      //   // link: "/purchase-report",
      //   icon: "mc-purchase-report",
      //   srole2: 'cuttingCenter',
      //   srole3: 'retailer'
      // },
      {
        id: 18,
        parentId: 5,
        label: "Bird Egg Frozen Feed Supplier",
        icon: "mc-supplier-management",
        link: "/supplier",
        srole4: 'franchise',
        srole3: 'cuttingCenter',
        srole2: 'retailer'
      },
      {
        id: 16,
        parentId: 5,
        label: "Bird Egg Frozen Feed Supplier Report",
        link: "/supplier-report",
        icon: "mc-supplier-report",
        srole4: 'franchise',
        srole3: 'cuttingCenter',
        srole2: 'retailer'
      },
    ],
  },


  // {
  //   id: 9,
  //   label: "Customers",
  //   icon: "mc-customers",
  //   link: "customer",
  //   role: "superAdmin",
  //   role2: "cuttingCenter",
  //   role3: "retailer",
  //   role4: "admin",
  //   role5: "franchise",
  //   role6: "superAdmin",
  //   role7: "superAdmin",
  // },


  {
    id: 16,
    label: "Delivery",
    icon: "mc-purchase",
    role7: "superAdmin",
    role: "admin",
    role2: "cuttingCenter",
    role3: "retailer",
    subItems: [
      {
        id: 16,
        parentId: 5,
        label: "Delivery Boy",
        icon: "mc-delivery",
        link: "/delivery-boy",
      },
      // {
      //   id: 16,
      //   parentId: 5,
      //   label: "Delivery Approval",
      //   icon: "mc-delivery",
      //   link: "/delivery-boy-approval",
      //   srole: "cuttingCenter",
      //   srole2: "retailer",
      // },
      {
        id: 18,
        parentId: 5,
        label: "Delivery Report",
        link: "/delivery-report",
        icon: "mc-delivery-report",
        // srole: "superAdmin",
        // srole2: "admin",
        // srole3: "franchise",
      },
    ],
  },





  {
    id: 25,
    label: "Stall Feed",
    icon: "mc-purchase",
    role7: "superAdmin",
    role4: "admin",
    role2: "franchise",
    role3: "cuttingCenter",
    role: "admin",
    subItems: [
      // {
      //   id: 18,
      //   parentId: 25,
      //   label: "Feed Category",
      //   icon: "mc-feeds",
      //   link: "feed",
      //   srole: "cuttingCenter",
      //   srole2: "retailer",
      //   srole4: "franchise",
      // },
      {
        id: 16,
        parentId: 25,
        label: "Feed Purchase Orders",
        icon: "mc-feed-purchase",
        link: "/feeds",
        srole2: "retailer",
      },
      // {
      //   id: 16,
      //   parentId: 25,
      //   label: "Feed Sales",
      //   icon: "mc-feed-purchase",
      //   // link: "/",
      //   srole: "cuttingCenter",
      //   srole2: "retailer",
      // },
      {
        id: 16,
        parentId: 25,
        label: "Feed Purchase Report",
        link: "/feed-report",
        icon: "mc-feed-report",
      },


    ],
  },

  {
    id: 5,
    label: "Monster Foods",
    icon: "mc-purchase",
    role7: "superAdmin",
    subItems: [
      {
        id: 16,
        parentId: 5,
        label: "Become a franchise",
        icon: "mc-product-purchase",
        link: "/become-a-franchise",
      },
      {
        id: 16,
        parentId: 5,
        label: "Contact Us",
        icon: "mc-feed-purchase",
        link: "/contact-us",
      },


    ],
  },
];
