import * as CryptoJS from "crypto-js";

export const defaultauth = "";
export const environment = {
  defaultauth: "fackbackend",
  production: true,
  secret: "testkey",
  enKey: CryptoJS.enc.Utf8.parse("s#Jv6ejUxs7MKcgyTkC3X9zZLjslGw2f"),
  enIv: CryptoJS.enc.Utf8.parse("K10Djpm7%9On%q7K"),
  firebase: {
    apiKey: "AIzaSyA6sNU2qO3_Oo4v0r86zcsAnM0VRJMB88Y",
    authDomain: "greenbound-cloudin.firebaseapp.com",
    projectId: "greenbound-cloudin",
    storageBucket: "greenbound-cloudin.appspot.com",
    messagingSenderId: "49201963292",
    appId: "1:49201963292:web:cb2f1647b919908f84dad9",
    measurementId: "G-NY8LVDYJHX",
    vapidKey:
      "BPCpLrKYNZvWrCwyCE3IXIZNvHy1xAqhpqDZpmlHV7rP7FGmAPqIRC5Esf1bnJEndH7-soqZ69Wqge4Dw7Xs9P0",
  },
};
