import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ApiCallService } from "./apiCall.service";
import { NetworkService } from "./network.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(private api: ApiCallService) {}

  getRoles() {
    return this.api.getApiCallAuth(NetworkService.roleList());
  }

  getProfile(): any {
    return this.api.getApiCallAuth(NetworkService.getProfile()).pipe(
      map((r: any) => {
        if (r.status == true) {
          // this.profile = r.response.profile_details;
          // this.name = r.response.userData.personalData.name;
          // this.gUser = r.response.google_user;
          // this.pChange.next(r.response.role);
          // this.role = r.response.userData.professionalData.type.name;
          // this.onBoarded = r.response.onBoarded;
        }
        return r;
      })
    );
  }

  // profile() {
  //   return this.api.getApiCallAuth(NetworkService.getProfile());
  // }

  updateProfile(data: any) {
    return this.api.postApiCallAuth(NetworkService.updateProfile(), data);
  }
}
