import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import MetisMenu from "metismenujs/dist/metismenujs";
import { EventService } from "../../core/services/event.service";

import { HttpClient } from "@angular/common/http";

import { TranslateService } from "@ngx-translate/core";
import { ActiveMenuService } from "src/app/services/active-menu.service";
import { AuthService } from "src/app/services/auth.service";
import { CommonService } from "src/app/services/common.service";
import { ProfileService } from "src/app/services/profile.service";
import { MENU } from "./menu";
import { MenuItem } from "./menu.model";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("componentRef") scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;
  count: any;
  role: string = "";
  menuItems = [];
  pendingCount: number = 0;
  activePrimaryMenu = "";
  activeSecondryMenu = "";
  menuLoading = true;
  collMenu: any[] = []

  @ViewChild("sideMenu") sideMenu: ElementRef;

  constructor(
    private eventService: EventService,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient,
    private ps: ProfileService,
    private cs: CommonService,
    private as: AuthService,
    private activeService: ActiveMenuService,
    private cdRef: ChangeDetectorRef
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });
  }

  ngOnInit() {

    this.getProfile();
    // this.sideBarColl(-1);
    this.initialize();
    this._scrollElement();
    this.activeService.activePrimaryMenu.subscribe((activePrimaryMenu) => {
      this.menuLoading = true;
      this.activePrimaryMenu = activePrimaryMenu;
      this.menuLoading = false;
      this.cdRef.markForCheck();
      // console.log('on Init');
      // console.log(this.activePrimaryMenu);
    });
    this.activeService.activeSecondryMenu.subscribe((activeSecondryMenu) => {
      this.menuLoading = true;
      this.activeSecondryMenu = activeSecondryMenu;
      this.menuLoading = false;
      this.cdRef.markForCheck();
      // console.log('on Init');
      // console.log(this.activeSecondryMenu);
    });
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
    this.activeService.activePrimaryMenu.subscribe((activePrimaryMenu) => {
      this.menuLoading = true;
      this.activePrimaryMenu = activePrimaryMenu;
      this.menuLoading = false;
      this.cdRef.markForCheck();
      // console.log('After View Init');
      // console.log(this.activePrimaryMenu);
    });
    this.activeService.activeSecondryMenu.subscribe((activeSecondryMenu) => {
      this.menuLoading = true;
      this.activeSecondryMenu = activeSecondryMenu;
      this.menuLoading = false;
      this.cdRef.markForCheck();
      // console.log('on Init');
      // console.log(this.activeSecondryMenu);
    });
  }

  toggleMenu(event) {
    event.currentTarget.nextElementSibling.classList.toggle("mm-show");
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }
  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0]["offsetTop"];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass("mm-active");
    this._removeAllClass("mm-show");
    const links = document.getElementsByClassName("side-nav-link-ref");
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }

    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    // console.log(window.location.pathname);
    if (menuItemEl) {
      menuItemEl.classList.add("active");
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add("mm-active");
        const parent2El = parentEl.parentElement.closest("ul");
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.add("mm-show");
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== "side-menu") {
            parent3El.classList.add("mm-active");
            const childAnchor = parent3El.querySelector(".has-arrow");
            const childDropdown = parent3El.querySelector(".has-dropdown");
            if (childAnchor) {
              childAnchor.classList.add("mm-active");
            }
            if (childDropdown) {
              childDropdown.classList.add("mm-active");
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== "side-menu") {
              parent4El.classList.add("mm-show");
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== "side-menu") {
                parent5El.classList.add("mm-active");
                const childanchor = parent5El.querySelector(".is-parent");
                if (childanchor && parent5El.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  getProfile() {
    this.cs.getprofile().subscribe((r: any) => {
      this.role = r.role;
      // console.log(this.role);

    });
  }

  logout() {
    this.as.logOut();
  }

  sideBarColl(i: number, item: any) {
    if (!this.hasItems(item)) return
    let index = this.collMenu.indexOf(i)
    if (index > -1) {
      this.collMenu.splice(index, 1)
    } else this.collMenu.push(i);
  }

  isColl(i: number) {
    let temp = this.menuItems[i]
    // console.error(temp.label)
    return !this.collMenu.includes(i) ? true : false;
  }
}
