import { Component, OnInit } from "@angular/core";
import { EventService } from "../../core/services/event.service";

import { LAYOUT_WIDTH, SIDEBAR_TYPE, TOPBAR } from "../layouts.model";

@Component({
  selector: "app-rightsidebar",
  templateUrl: "./rightsidebar.component.html",
  styleUrls: ["./rightsidebar.component.scss"],
})
export class RightsidebarComponent implements OnInit {
  products = ["Arun", "Rishi", "Karthick"];
  constructor(private eventService: EventService) {}

  ngOnInit() {}

  /**
   * Hide the sidebar
   */
  public hide() {
    document.body.classList.remove("right-bar-enabled");
  }
}
