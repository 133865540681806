import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class EncryptService {
  constructor() { }
  private key = environment.enKey;
  private iv = environment.enIv;
  cfg: any = {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    cipher: CryptoJS.AES,
    iv: this.iv,
  };

  //AES256 Encryption
  encrypt(req: any) {
    let data = CryptoJS.enc.Utf8.parse(JSON.stringify(req));
    var encryptedMessage = CryptoJS.AES.encrypt(data, this.key, this.cfg);
    return encryptedMessage.toString();
  }

  //AES256 Decryption
  decrypt(req: any) {
    var decrypt = CryptoJS.AES.decrypt(req, this.key, this.cfg);
    var decryptedMessage2 = CryptoJS.enc.Utf8.stringify(decrypt);
    return JSON.parse(decryptedMessage2);
  }

  //to mask input JSON body
  dataIn(data: any): any {
    let output = {
      input: this.encrypt(data),
    };
    return output;
  }

  dataInParam(data: any): any {
    let output = {
      input: this.encrypt(data),
    };
    let res = output.input
    
    return res;
  }

  encode(data) {
    let result = encodeURIComponent(data).replace(/'/g, "%27").replace(/"/g, "%22");
    return result
  }

  //unmask encryption to JSON
  unmaskData(data: any): JSON {
    let output = this.decrypt(data.data);
    return output;
  }


  unmaskErrData(data: any) {
    let output = this.decrypt(data.data);
    return output;
  }

  dataIn2(data: any): any {
    // let output = {
    //   input: this.encrypt(data),
    // };
    return this.encrypt(data);
  }
}
